<template>
  <b-card
    title="Outstanding Report"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>
    <outstanding-details
      v-if="displayDetailsforVendor"
      :buyer-id="displayDetailsforVendor"
      @modal-closed="displayDetailsforVendor = null"
    />
    <b-table
      v-if="!loading && !loadingError"
      :items="items"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      sort-by="total_outstanding"
      sort-desc
      class="compact-table"
      sticky-header="calc(100vh - 190px)"
    >
      <template #cell(total_outstanding)="data">
        {{ formateNumber(data.item.total_outstanding) }}
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayDetails(data.item.party__id)"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BTable, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import formatMixin from '@/mixins/formatMixin'
import OutstandingDetails from '../../components/OutstandingReport/OutstandingDetails.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    OutstandingDetails, BCard, BSpinner, BTable, BAlert,
  },
  mixins: [formatMixin],
  data() {
    return {
      loading: true,
      loadingError: null,
      items: [],
      tableFields: [
        { key: 'party__identifier', label: 'Buyer', sortable: true },
        {
          key: 'total_outstanding', label: 'Total Outstanding', sortable: true, class: 'text-right pr-2',
        },
        { key: 'pending_bills', label: 'Pending Bills', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
      displayDetailsforVendor: null,
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      this.loading = true
      axios.get('/accounts/outstanding_summary')
        .then(res => {
          this.items = res.data.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },

    displayDetails(buyerId) {
      this.displayDetailsforVendor = buyerId
    },
  },
}
</script>
