<template>
  <b-modal
    v-model="modalShow"
    size="xl"
    :title="buyerDetails.buyer"
    scrollable
    @hidden="$emit('modal-closed')"
  >

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">

      <b-row>
        <b-col>
          <div class="card">
            <div class="card-body d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ buyerDetails.total_outstanding }}
                </h2>
                <span>Total Outstanding</span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="card">
            <div class="card-body d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ buyerDetails.pending_bills }}
                </h2>
                <span>Pending Bills</span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-table
        :items="buyerDetails.records"
        :fields="tableFields"
        striped
        hover
        sort-by="bill_no"
        sort-asc
        class="compact-table"
      >
        <template #cell(date)="data">
          {{ formatedDate(data.item.date) }}
        </template>
        <template #cell(amount)="data">
          {{ formateNumber(data.item.amount) }}
        </template>
        <template #cell(due_amount)="data">
          {{ formateNumber(data.item.due_amount) }}
        </template>
        <template #cell(pay_status)="data">
          <status-icon :value="data.item.pay_status" />
        </template>
      </b-table>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="modalShow=false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BTable, BButton, BCol, BRow, BAlert, BSpinner,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import StatusIcon from '../UI/StatusIcon.vue'

export default {
  components: {
    BTable, BButton, BCol, BRow, BAlert, BSpinner, StatusIcon,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    buyerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      buyerDetails: {},
      modalShow: true,
      loadingError: null,
      loading: true,
      tableFields: [
        { key: 'bill_no', label: 'Bill No', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        {
          key: 'amount', label: 'Amount', sortable: true, class: 'text-right',
        },
        {
          key: 'due_amount', label: 'Due Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'pay_status', label: 'Pay Status', sortable: true },
        { key: 'statement_date', label: 'Statement Date', sortable: true },
      ],
    }
  },
  created() {
    this.fetchItemDetails()
  },
  methods: {
    fetchItemDetails() {
      axios.get(`/accounts/outstanding_details/${this.buyerId}`)
        .then(res => {
          this.buyerDetails = res.data.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
  },
}
</script>
